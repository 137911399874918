.button {
  display: block;
  font-size: 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  text-decoration: none;
  transition: transform 100ms var(--bounce);
  user-select: none;
  text-align: center;
  background: var(--white);
  padding: 4px;
  -webkit-appearance: none;
  box-sizing: border-box;
  background: transparent;
}

.button div {
  text-align: center;
  font-size: 18px;
  padding: 1em 0;
  background: var(--white);
  border: solid 4px #393939;
  border-radius: 32px;
  color: #393939;
}

.button.white div {
  border-width: 0;
}

.button.has-active-touch {
  /* transform: scale(1.2); */
}

a:visited {
  color: #393939;
}
a:active {
  color: #393939;
}

.circle-button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--white);
}

.circle-button div {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.icon-button div {
  width: 1em;
  height: 1em;
}

.label {
  font-size: 16px;
  padding: 1em;
  background: #393939;
  border: 4px solid white;
  display: inline-block;
}

.slider {
  display: inline-block;
  /* width: 50%; */
  height: 400px;
  text-align: center;
  position: absolute;
  top: calc(var(--app-height) * 0.2);
}

.slider .label {
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0;
  opacity: 0;
  margin-top: -25px;
  transform: translateX(-50%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.slider .slider-range {
  width: 4px;
  height: 100%;
  background: var(--white);
  margin: auto;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.left-slider {
  left: 5px;
}

.right-slider {
  right: 5px;
}

.left-slider .label {
  left: 0;
  margin-left: 0;
  transform: translate(-50%, -125%);
}

.right-slider .label {
  right: 0;
  transform: translate(50%, -125%);
  margin-right: 0;
}

.slider.is-active .label {
  transform: translate(0%, -125%);
  opacity: 1;
}

.slider.is-active .slider-range {
  opacity: 1;
}

.slider.is-active .icon-button {
  transform: scale(1.2);
}

.button.icon-button {
  background: transparent;
  border: none;
}

.button.icon-button div {
  background: transparent;
  border: none;
  padding: 0;
  width: 44px;
  height: 44px;
}

.button.icon-button img {
  width: 44px;
}

.button.copy-button {
  width: 100%;
  max-width: 450px;
}

.button.copy-button div {
  letter-spacing: 0.15em;
  padding: 1em 0;
  position: relative;
}

.button.copy-button.red div {
  color: var(--red);
}

.button.copy-button .emoji {
  height: 2em;
  /* margin: 0 0.05em 0 0.1em;
  vertical-align: -0.5em; */
  position: absolute;
  left: 1.125em;
  top: 50%;
  transform: translateY(-50%);
}

.button.copy-button .emoji:last-child {
  left: auto;
  right: 1.125em;
}

.button.copy-button .emoji.camera-flash {
  transform: translateY(calc(-50% - 2px));
}
